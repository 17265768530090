<template>
  <div class="wrapper">
    <div class="card">
      <van-form ref="form">
        <van-field
          v-model="tys.fddbrxm"
          label="姓名"
          required
          readonly
        ></van-field>
        <van-field
          v-model="tys.wtrlxdh"
          label="电话"
          required
          readonly
        ></van-field>
        <van-field
          v-model="form.post"
          label="职务"
          required
          placeholder="请输入职务"
          :rules="[{ required: true, message: '职务不能为空' }]"
        ></van-field>
        <van-field
          v-model="form.address"
          label="住所"
          required
          placeholder="请输入住所地"
          :rules="[{ required: true, message: '住所不能为空' }]"
        ></van-field>
        <van-field
          v-model="form.sms"
          center
          clearable
          required
          label="验证码"
          placeholder="请输短信验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              :disabled="countDown !== 60"
              :loading="smsSending"
              @click="sendSms"
              style="width: 89px"
            >
              {{ smsBtnTxt }}
            </van-button>
          </template>
        </van-field>
      </van-form>
    </div>
    <van-checkbox
      v-model="form.agreementCheck"
      icon-size="0.36rem"
      shape="square"
      checked-color="#7232dd"
      class="agreement-checkbox"
      style="margin-top: 20px"
    >
      <div>
        我已阅读并同意<span class="agreement-link" @click.stop="showAgreement"
          >《涉税信息查询同意书》</span
        >
      </div>
    </van-checkbox>
    <van-popup v-model="agreementShow" position="bottom">
      <hmd-query-tax-info-agreement
        :tys="tys"
        @close="closeAgreement"
        @agree="agreeAgreement"
      ></hmd-query-tax-info-agreement>
    </van-popup>
    <van-button
      color="#7232dd"
      block
      :loading="submitLoading"
      @click="submitHandle"
      class="submit-btn"
    >
      同意授权
    </van-button>
  </div>
</template>

<script>
import { Form, Field, Checkbox, Button, Popup, Toast } from "vant";
import HmdQueryTaxInfoAgreement from "./components/HmdQueryTaxInfoAgreement";
import EtaxApi from "../../api/etax";

export default {
  name: "HmdPage",
  components: {
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [Form.name]: Form,
    HmdQueryTaxInfoAgreement,
  },
  props: {
    orderId: String,
  },
  data() {
    return {
      agreementShow: false,
      form: {
        orderId: this.orderId,
        agreementCheck: false,
        phone: "",
        post: "",
        address: "",
        sms: "",
      },
      tys: {},
      countDown: 60,
      intervalTimer: null,
      smsSending: false,
      submitLoading: false,
    };
  },
  computed: {
    smsBtnTxt() {
      if (this.countDown === 60) {
        return "发送验证码";
      } else {
        return this.countDown + "s";
      }
    },
  },
  watch: {
    orderId(val) {
      this.form.orderId = val;
      this.initData();
    },
  },
  mounted() {
    this.initData();
  },
  methods: {
    showAgreement() {
      this.agreementShow = true;
    },
    closeAgreement() {
      this.agreementShow = false;
    },
    agreeAgreement() {
      this.agreementShow = false;
      this.form.agreementCheck = true;
    },
    initData() {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
        className: "loading-toast",
      });
      EtaxApi.hmdInit(this.orderId)
        .then((res) => {
          this.tys = res.data.initTys;
        })
        .finally(() => {
          Toast.clear();
        });
    },
    sendSms() {
      this.smsSending = true;
      EtaxApi.hmdSendSms(this.tys.nsrsbh)
        .then(() => {
          this.countDown--;
          this.intervalTimer = setInterval(() => {
            if (this.countDown > 1) {
              this.countDown--;
            } else {
              clearInterval(this.intervalTimer);
              this.countDown = 60;
            }
          }, 1000);
        })
        .finally(() => {
          this.smsSending = false;
        });
    },
    submitHandle() {
      this.$refs.form.validate().then(() => {
        if (!this.form.agreementCheck) {
          Toast.fail("请先阅读《同意书》");
        } else {
          this.submitLoading = false;
          EtaxApi.hmdAuth(this.form)
            .then((res) => {
              if (res.data.goodsId === "A10") {
                location.href =
                  "https://lsd.lsccb.com/cfs_server/wx/wxBind.do?wxchannel=LS&outchannel=jz_ch";
              } else if (res.data.goodsId === "A7") {
                this.$router.push("/pingan");
              } else {
                this.gotoStatusPage();
              }
            })
            .finally(() => {
              this.submitLoading = false;
            });
        }
      });
    },
    gotoStatusPage() {
      this.$router.push("/apply/status/" + this.orderId);
    },
  },
};
</script>

<style scoped>
.wrapper {
  padding: 30px;
  font-size: 28px;
}

.card {
  /*background-color: #B390EE;*/
  border: 1px solid #e3e3e3;
  border-radius: 15px;
  padding: 50px 0;
}

.submit-btn {
  margin-top: 50px;
  line-height: 76px;
  height: 100%;
}

.agreement-link {
  color: #7232dd;
}
</style>