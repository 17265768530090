<template>
  <div class="content-wrapper">
    <div>
      <div class="tys-title" style="text-align: center">声明</div>
      <p class="text-blod" style="margin: 10px 0">国家税务总局四川省税务局：</p>
      <p>一、因申请贷款需要，本单位/本人已委托中国建设银行四川省分行通过包括专线在内的电子数据传输方式向贵局查询、摘录、拷贝本单位/本人税务登记基本信息、税费信息和纳税信用信息。
        现将本单位/本人向中国建设银行四川省分行作出的《授权委托书》附后，望贵局予以准许。本人在此承诺，对《授权委托书》的客观性负责，承担该《授权委托书》致使的一切法律责任。</p>
      <p>二、本单位/本人已授权中国建设银行四川省分行按时向贵局报送本单位/本人贷款申请信息（资料）、贷款获批信息（资料）、贷款支付信息（资料）、贷款归还信息（资料）、
        利息支付信息（资料）、贷款催收信息（资料）、违约信息（资料）、因贷款违约被诉讼、仲裁信息（资料）或被追究刑事责任信息（资料）、因贷款违约被银行申请强制执行信息（资料）及信贷效应、
        企业信用评价信息（资料）和银行及相关机构对本单位/本人贷款分析信息（资料）。受托人未及时履行前述义务的后果由本单位/本人承担。</p>
      <p>
        三、本单位/本人同意贵局保存、使用、处置中国建设银行四川省分行交付给贵局的本单位/本人的贷款申请信息（资料）、贷款获批信息（资料）、贷款支付信息（资料）、贷款归还信息（资料）、利息支付信息（资料）、贷款催收信息（资料）、违约信息（资料）、因贷款违约被诉讼、
        仲裁信息（资料）或被追究刑事责任信息（资料）、因贷款违约被申请强制执行信息（资料）及信贷效应、企业信用评价信息（资料）以及银行及相关机构对本单位/本人的贷款分析信息（资料）。
        本人承诺：因贵局保存、使用、处置本单位/本人前述信息（资料）致使的后果由本单位/本人负责和承担。</p>
      <p>四、本单位/本人向中国建设银行四川省分行的授权委托为不可撤销的授权委托。</p>
      <p style="margin: 10px 0">声明人：<span class="text-blod">{{ tys.wtr }}</span></p>
      <p style="margin: 10px 0">日期：<span class="text-blod">{{ smrq }}</span></p>
    </div>
    <div style="margin: 50px 0">
      <div class="tys-title" style="text-align: center">授权委托书</div>
      <p>委托人：<span class="text-blod">{{ tys.wtr }}</span></p>
      <p>统一信用代码/证件类型及号码：<span class="text-blod">{{ tys.nsrsbh }}</span></p>
      <p>法定代表人：<span class="text-blod">{{ tys.fddbrxm }}</span><span style="margin-left: 30px">身份证号码：</span><span
          class="text-blod">{{ tys.fddbrsfzh }}</span></p>
      <p class="margin-top20">受托人：<span class="text-blod">{{ tys.str }}</span></p>
      <p>法定代表人：<span class="text-blod">{{ tys.strfddbrxm }}</span><span style="margin-left: 30px">职务：</span><span
          class="text-blod">{{ tys.strzw }}</span></p>
      <p>住所地：<span class="text-blod">{{ tys.strszd }}</span></p>
      <p>联系电话：<span class="text-blod">{{ tys.strlxdh }}</span></p>
      <div class="margin-top20">
        <p>一、委托原由：</p>
        <p style="text-indent: 2em">委托人因在受托人处申请、办理贷款业务（以下简称“本次业务”）需要。</p>
        <p>二、委托事项：</p>
        <p style="text-indent: 2em;">委托受托人到国家税务局四川省税务局（以下简称四川省税务局）通过包括专线在内的电子数据传输方式查询、摘录、
          拷贝受托人为委托人授贷需要的税务登记基本信息、税、费信息（数据）纳税信用信息。</p>
        <p>三、受托人代理权限：</p>
        <p style="text-indent: 2em;">1、通过包括专线在内的电子数据传输方式向四川省税务局查询、摘录、拷贝截止委托人向受托人的贷款申请受理日前24个月（含申请所在当月）及贷款申请受理次
          月至最后期贷款支付到委托人的银行账户当月以及自最后一期贷款支付到委托人的银行账户次月1日起届满730日止期间，包括但不限于税务登记基本信息、税费征管信息、税费优惠信息、
          税收案件查处信息、欠税公告信息、欠税催缴信息、税费强制执行信息、外部传来税费信息以及纳税信用、税费风险分析评估等税费信息。若贷款申请未获受托人批准，前述期限止于受托人
          向委托人送达到不予批准贷款申请告知之日。上款“月”为公历月。</p>
        <p style="text-indent: 2em;">
          2、委托代理人按照四川省税务局的要求按时将委托人在代理人处的包括但不限于贷款申请信息、贷款获批信息、贷款支付信息、贷款归还信息、利息支付信息、贷款催收信息、委托人违约信息、
          因贷款违约被诉讼、仲裁信息或被追究刑事责任信息、因贷款违约被代理人申请强制执行信息、信贷效应、企业信用评价以及代理人及相关机构对委托人基于贷款的分析信息通过包括专线在内的
          电子数据传输方式交付四川省税务局。为确保受托人能按时按四川省税务局要求将前述信息交付，特授权受托人以自己的名义与四川省税务局签订交付前述贷款信息及违反责任的契约，由此产生的 责任及后果均由本委托人承担。</p>
        <p style="text-indent: 2em;">3、授权受托人分析、使用依据本《授权委托书》从四川省税务局查询、摘录、拷贝的委托人的税费信息。</p>
        <p>四、授权期限：</p>
        <p style="text-indent: 2em">
          本《授权委托书》自本委托书署名的当日起至最后一期贷款交付至委托人银行账户之次日起届满730日止有效；若贷款申请未获批准，本《授权委托书》自本委托书署名的当日起至受托人送达到委托人不予批准贷款申请的告知当日。
          但本《授权委托书》中第二项代理权限涉及事务不受本条前述期限的限制。</p>
        <p>五、委托人声明：</p>
        <p style="text-indent: 2em">1、本《授权委托书》授权为不可撤销的授权。</p>
        <p style="text-indent: 2em">2、该授权委托书是委托人的真实意思表示，委托人清楚《授权委托书》下法律责任</p>
      </div>
      <p class="margin-top20">委托人：<span class="text-blod">{{ tys.wtr }}</span></p>
      <p class="margin-top20">签署日期：<span class="text-blod">{{ smrq }}</span></p>
    </div>
    <div class="btn-group">
      <van-button class="btn-item" style="margin-right: 20px" plain @click="close">关闭</van-button>
      <van-button class="btn-item" color="#7232dd" @click="agree">同意</van-button>
    </div>
  </div>
</template>

<script>
import {Button} from 'vant'

export default {
  name: "HmdQueryTaxInfoAgreement",
  components: {
    [Button.name]: Button
  },
  props: {
    tys: Object
  },
  computed: {
    smrq() {
      return (new Date()).format('yyyy-MM-dd')
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    agree() {
      this.$emit('agree')
    }
  }
}
</script>

<style scoped>
.content-wrapper {
  padding: 15px;
}

.tys-title {
  font-weight: bold;
  font-size: 32px;
}

.text-blod {
  font-weight: bold;
}

.btn-group {
  background-color: #ffffff;
  border-top: 1px solid #eeeeee;
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 15px 0;
}

.btn-item {
  height: 60px;
  width: 150px;
}
</style>